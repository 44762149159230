import React from 'react'
import PropTypes from 'prop-types'

const Facebook = ({ width = 12 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={width * 2}
    viewBox="0 0 3.394 6.638">
    <path
      fill="#58595B"
      fillRule="evenodd"
      d="M3.394.01v1.007c-.386.107-.844.107-1.07.34-.193.198-.12.659-.172 1.067h1.215v1.18H2.206v3.034H1V3.63H0V2.445h.975C1.018 1.667.826.843 1.551.299 2.098-.114 2.738.027 3.394.01z"
      clipRule="evenodd"
    />
  </svg>
)

Facebook.propTypes = {
  width: PropTypes.number
}

export default Facebook
