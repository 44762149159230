import styled, { css } from 'styled-components'

export const primaryFont = css`
  font-family: 'Poiret One', cursive;
`

export const secondaryFont = css`
  font-family: 'GFS Neohellenic', sans-serif;
`

export const Text = styled.span`
  ${primaryFont};
`

export const SecondaryText = styled.span`
  ${secondaryFont};
`

export const PageTitle = styled.h1`
  ${primaryFont};
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: normal;
`

export const Input = styled.input`
  ${primaryFont};
  border-radius: 2px;
  box-sizing: border-box;
  border: 1px solid #000;
  box-shadow: 2px 3px 4px 0 rgba(26, 24, 24, 0.25);
  padding-left: 5px;
  height: 30px;
  margin-bottom: 25px;
  font-size: 15px;
  &:focus {
    outline: none;
    box-shadow: 0 0 2px 2px rgba(26, 24, 24, 0.55);
  }
`

export const Button = styled.button`
  ${primaryFont};
  background: #4d8fa3;
  border: 0.5px solid #4d8fa3;
  font-size: 18px;
  font-weight: bold;
  border-radius: 28px;
  text-transform: uppercase;
  color: #fff;
  padding: 0;
  width: 100%;
  max-width: 400px;
  display: block;
  outline: none;
  :hover:not([disabled]) {
    box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, 0.25);
  }
  :active:not([disabled]) {
    border: solid 0.5px #ffffff;
    background-color: #1c84a3;
    box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, 0.25);
  }
  ${props => props.loading && 'opacity: 0.7'};
`

export const SecondaryButton = styled.button`
  ${primaryFont};
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.1),
    rgba(0, 0, 0, 0.1)
  );
  font-size: 18px;
  color: #000;
  text-transform: uppercase;
  padding: 0 5px;
  display: block;
  border: 1px solid #191919;
  border-radius: 3px;
`
