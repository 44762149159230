import React from 'react'
import PropTypes from 'prop-types'

const Instagram = ({ width = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={width}
    viewBox="0 0 6.656 6.638">
    <path
      fill="#58595B"
      fillRule="evenodd"
      d="M.002 3.318c0-.8-.004-1.6 0-2.4.002-.594.32-.922.916-.924 1.612-.006 3.225-.006 4.839.002.572.002.896.336.896.904.004 1.612.004 3.225 0 4.839 0 .568-.324.902-.896.904-1.612.008-3.227.006-4.839 0-.596-.002-.914-.33-.916-.922-.004-.8 0-1.6 0-2.403zm1.323-.483H.731c0 .926-.006 1.843.004 2.757.002.216.158.287.365.287 1.482-.004 2.965-.006 4.447 0 .262.002.361-.12.359-.364-.002-.316 0-.635 0-.951.002-.574 0-1.146 0-1.723h-.561c.166.95-.09 1.719-.904 2.222-.743.458-1.527.448-2.26-.032-.785-.512-1.029-1.272-.856-2.196zm1.994 1.758c.749.004 1.351-.569 1.351-1.288 0-.705-.586-1.279-1.319-1.289-.738-.012-1.359.578-1.357 1.291.002.698.604 1.282 1.325 1.286zm1.884-2.548c.682 0 .699-.016.699-.646 0-.652-.027-.682-.633-.682-.681-.002-.726.041-.726.67.001.617.042.658.66.658z"
      clipRule="evenodd"
    />
  </svg>
)

Instagram.propTypes = {
  width: PropTypes.number
}

export default Instagram
