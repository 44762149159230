import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import { Link } from 'gatsby'
import waveBackground from '../../images/menu/wave_background.png'
import { primaryFont } from '../../GlobalStyles'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  margin-bottom: 25px;
`

export const Row = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
  width: 90%;
  ${breakpoint('tablet')`
    width: 70%;
  `}
`

export const WaveBackground = styled.div`
  position: absolute;
  background-image: url(${waveBackground});
  background-repeat: repeat-x;
  background-size: 500px auto;
  height: 20px;
  width: 10000px;
  left: -5000px;
  bottom: 35px;
  ${breakpoint('desktop')`
    bottom: 45px;
  `}
`

export const Text = styled.h2`
  ${primaryFont};
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 0;
  white-space: nowrap;
  ${breakpoint('desktop')`
    font-size: 25px;
  `}
`

export const MenuIcon = styled.img`
  height: 60px;
  margin-bottom: 0;
  opacity: 0.5;
`

const isActiveClass = 'isActive'
export const LinkItem = styled(Link).attrs({
  activeClassName: isActiveClass
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #000;
  width: 33%;
  color: rgba(0, 0, 0, 0.4);
  ${props => (props.proper ? `${MenuIcon} { opacity: 1;}` : '')};
  ${props => (props.proper ? `${Text} { color: #000;}` : '')};
  &.${isActiveClass} {
    z-index: 1;
    ${MenuIcon} {
      opacity: 1;
    }
    > ${Text} {
      font-weight: bold;
      color: #000;
    }
  }
  :hover {
    z-index: 1;
  }
`
