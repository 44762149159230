import * as R from 'ramda'

const wines = [
  {
    category: { en: 'white wines', el: 'white wines' },
    subCategories: [
      {
        name: { en: 'aromatic & fruity', el: 'aromatic & fruity' },
        color: '#a58922',
        items: [
          {
            name: { en: 'MANTINÍA', el: 'MANTINÍA' },
            type: {
              en: 'MOSHOFÍLERO',
              el: 'MOSHOFÍLERO'
            },
            producer: {
              en: 'Bosinakis Winery / PDO Mantinia',
              el: 'Bosinakis Winery / PDO Mantinia'
            }
          },
          {
            name: { en: 'MALAGOUZIÁ', el: 'MALAGOUZIÁ' },
            type: {
              en: 'MALAGOUZIÁ',
              el: 'MALAGOUZIÁ'
            },
            producer: {
              en: 'Dom. Gerovassiliou / PGI Epanomi \n/ single vineyard',
              el: 'Dom. Gerovassiliou / PGI Epanomi \n/ single vineyard'
            }
          },
          {
            name: { en: 'ROBÓLA', el: 'ROBÓLA' },
            type: {
              en: 'ROBÓLA',
              el: 'ROBÓLA'
            },
            producer: {
              en: 'Gentillini Winery / PDO Robola Kefallinias',
              el: 'Gentillini Winery / PDO Robola Kefallinias'
            }
          }
        ]
      },
      {
        name: { en: 'complex & crisp', el: 'complex & crisp' },
        color: '#a58922',
        items: [
          {
            name: { en: 'SANTORÍNI', el: 'SANTORÍNI' },
            type: {
              en: 'ASÝRTIKO, ATHÍRI, AIDÁNI',
              el: 'ASÝRTIKO, ATHÍRI, AIDÁNI'
            },
            producer: {
              en: 'Karamolegos Winery / PDO Santorini',
              el: 'Karamolegos Winery / PDO Santorini'
            }
          },
          {
            name: { en: 'SANCERRE', el: 'SANCERRE' },
            type: {
              en: 'SAUVIGNON BLANC',
              el: 'SAUVIGNON BLANC'
            },
            producer: {
              en: 'Dom. Vacheron / PDO Sancerre',
              el: 'Dom. Vacheron / PDO Sancerre'
            }
          },
          {
            name: {
              en: 'SANTORÍNI MEGA CUVÉE / PYRITIS',
              el: 'SANTORÍNI MEGA CUVÉE / PYRITIS'
            },
            type: {
              en: 'ASÝRTIKO',
              el: 'ASÝRTIKO'
            },
            producer: {
              en: 'Karamolegos Winery / PDO Santorini\n/ancestor vines',
              el: 'Karamolegos Winery / PDO Santorini\n/ancestor vines'
            }
          }
        ]
      },
      {
        name: { en: 'oak & rich', el: 'oak & rich' },
        color: '#a58922',
        items: [
          {
            name: { en: 'WHITE HARE', el: 'WHITE HARE' },
            type: {
              en: 'VIDIANÓ',
              el: 'VIDIANÓ'
            },
            producer: {
              en: 'Douloufakis Winery / PGI Crete',
              el: 'Douloufakis Winery / PGI Crete'
            }
          },
          {
            name: { en: 'CHABLIS CHAMPS ROYAUX', el: 'CHABLIS CHAMPS ROYAUX' },
            type: {
              en: 'CHARDONNAY',
              el: 'CHARDONNAY'
            },
            producer: {
              en: 'Dom. W. Fevre / PDO Chablis',
              el: 'Dom. W. Fevre / PDO Chablis'
            }
          },
          {
            name: {
              en: 'BLANC DE BLANCS',
              el: 'BLANC DE BLANCS'
            },
            type: {
              en: 'SEMILLON, ROLLE',
              el: 'SEMILLON, ROLLE'
            },
            producer: {
              en: 'Dom. Ott -Clos Mireille \n/ PDO Côtes de Provence',
              el: 'Dom. Ott -Clos Mireille \n/ PDO Côtes de Provence'
            }
          }
        ]
      }
    ]
  },
  {
    category: { en: 'ROSE WINES', el: 'ROSE WINES' },
    subCategories: [
      {
        name: { en: 'elegant & fruity', el: 'elegant & fruity' },
        color: '#f375b0',
        items: [
          {
            name: { en: 'FEGGÍTES', el: 'FEGGÍTES' },
            type: {
              en: 'CABERNET SAUVIGNON, GRENACHE ROUGE',
              el: 'CABERNET SAUVIGNON, GRENACHE ROUGE'
            },
            producer: {
              en: 'Oenogenesis Wines / PGI Drama',
              el: 'Oenogenesis Wines / PGI Drama'
            }
          },
          {
            name: { en: 'PÉTALE DE ROSE', el: 'PÉTALE DE ROSE' },
            type: {
              en: 'GRENACHE, CINSAULT, CABERNET SAUVIGNON',
              el: 'GRENACHE, CINSAULT, CABERNET SAUVIGNON'
            },
            producer: {
              en: 'Régine Sumeire / PDO Côtes de Provence',
              el: 'Régine Sumeire / PDO Côtes de Provence'
            }
          },
          {
            name: {
              en: 'COEUR DE GRAIN',
              el: 'COEUR DE GRAIN'
            },
            type: {
              en: 'MOURVÈRDE, GRENACHE, CINSAULT, SYRAH',
              el: 'MOURVÈRDE, GRENACHE, CINSAULT, SYRAH'
            },
            producer: {
              en: 'Dom. Ott -Château Romassan / PDO Bandol',
              el: 'Dom. Ott -Château Romassan / PDO Bandol'
            }
          }
        ]
      },
      {
        name: { en: 'crisp & refreshing', el: 'crisp & refreshing' },
        color: '#f375b0',
        items: [
          {
            name: { en: '4-6 H', el: '4-6 H' },
            type: {
              en: 'AGIORGÍTIKO',
              el: 'AGIORGÍTIKO'
            },
            producer: {
              en: 'Géa Dom. / PGI Peloppónisos',
              el: 'Géa Dom. / PGI Peloppónisos'
            }
          },
          {
            name: { en: 'ALPHA', el: 'ALPHA' },
            type: {
              en: 'XINÓMAVRO',
              el: 'XINÓMAVRO'
            },
            producer: {
              en: 'Alpha Estate / PDO Amίnteon',
              el: 'Alpha Estate / PDO Amίnteon'
            }
          },
          {
            name: {
              en: 'ROSE THEÓPETRA',
              el: 'ROSE THEÓPETRA'
            },
            type: {
              en: 'LIMNIÓNA, SYRAH',
              el: 'LIMNIÓNA, SYRAH'
            },
            producer: {
              en: 'Theópetra Estate / PGI Metéora',
              el: 'Theópetra Estate / PGI Metéora'
            }
          }
        ]
      }
    ]
  },
  {
    category: { en: 'SPARKLING WINES', el: 'SPARKLING WINES' },
    items: [
      {
        name: {
          en: 'AMALÍA BRUT',
          el: 'AMALÍA BRUT'
        },
        type: {
          en: 'MOSHOFÍLERO',
          el: 'MOSHOFÍLERO'
        },
        producer: {
          en: 'Tsélepos Winery',
          el: 'Tsélepos Winery'
        }
      },
      {
        name: {
          en: 'PROSECCO DI GANCIA',
          el: 'PROSECCO DI GANCIA'
        },
        type: {
          en: 'PROSECCO',
          el: 'PROSECCO'
        },
        producer: {
          en: 'PDO Prosecco',
          el: 'PDO Prosecco'
        }
      },
      {
        name: {
          en: 'AKAKÍES ROSÉ',
          el: 'AKAKÍES ROSÉ'
        },
        type: {
          en: 'XINÓMAVRO',
          el: 'XINÓMAVRO'
        },
        producer: {
          en: 'Dom. Kyr-Yanni / PDO Amίnteon',
          el: 'Dom. Kyr-Yanni / PDO Amίnteon'
        }
      }
    ]
  },
  {
    category: { en: 'CHAMPAGNE', el: 'CHAMPAGNE' },
    items: [
      {
        name: {
          en: 'MOËT IMPÉRIAL',
          el: 'MOËT IMPÉRIAL'
        },
        type: {
          en: 'PINOT NOIR, PINOT MEUNIER, CHARDONNAY',
          el: 'PINOT NOIR, PINOT MEUNIER, CHARDONNAY'
        },
        producer: {
          en: 'Moët & Chandon / PDO Champagne',
          el: 'Moët & Chandon / PDO Champagne'
        }
      },
      {
        name: {
          en: 'VEUVE CLIQUOT BRUT',
          el: 'VEUVE CLIQUOT BRUT'
        },
        type: {
          en: 'PINOT NOIR, PINOT MEUNIER, CHARDONNAY',
          el: 'PINOT NOIR, PINOT MEUNIER, CHARDONNAY'
        },
        producer: {
          en: 'VEUVE CLIQUOT / PDO Champagne',
          el: 'VEUVE CLIQUOT / PDO Champagne'
        }
      },
      {
        name: {
          en: 'MOËT ROSÉ IMPÉRIAL',
          el: 'MOËT ROSÉ IMPÉRIAL'
        },
        type: {
          en: 'PINOT NOIR, PINOT MEUNIER, CHARDONNAY',
          el: 'PINOT NOIR, PINOT MEUNIER, CHARDONNAY'
        },
        producer: {
          en: 'Moët & Chandon / PDO Champagne',
          el: 'Moët & Chandon / PDO Champagne'
        }
      }
    ]
  },
  {
    category: { en: 'RED WINES', el: 'RED WINES' },
    subCategories: [
      {
        name: { en: 'fruits & freshness', el: 'fruits & freshness' },
        color: '#dc1342',
        items: [
          {
            name: {
              en: 'RAPSÁNI',
              el: 'RAPSÁNI'
            },
            type: {
              en: 'XINÓMAVRO, KRASÁTO, STAVROTÓ',
              el: 'XINÓMAVRO, KRASÁTO, STAVROTÓ'
            },
            producer: {
              en: 'Dougos Winery / PDO Rapsáni',
              el: 'Dougos Winery / PDO Rapsáni'
            }
          },
          {
            name: {
              en: 'LIMNIÓNA',
              el: 'LIMNIÓNA'
            },
            type: {
              en: 'LIMNIÓNA',
              el: 'LIMNIÓNA'
            },
            producer: {
              en: 'Dom. Zafirákis / PGI Tírnavos',
              el: 'Dom. Zafirákis / PGI Tírnavos'
            }
          }
        ]
      },
      {
        name: { en: 'spices & structure', el: 'spices & structure' },
        color: '#dc1342',
        items: [
          {
            name: {
              en: 'RÁMNISTA',
              el: 'RÁMNISTA'
            },
            type: {
              en: 'XINÓMAVRO',
              el: 'XINÓMAVRO'
            },
            producer: {
              en: 'Dom. Kyr-Yanni  / PDO Náousa',
              el: 'Dom. Kyr-Yanni  / PDO Náousa'
            }
          },
          {
            name: {
              en: 'MAVROÚDI FINE',
              el: 'MAVROÚDI FINE'
            },
            type: {
              en: 'MAVROÚDI, CABERNET SAUVIGNON',
              el: 'MAVROÚDI, CABERNET SAUVIGNON'
            },
            producer: {
              en: 'Anatolikós Vineyards / wild fermentation',
              el: 'Anatolikós Vineyards / wild fermentation'
            }
          },
          {
            name: {
              en: 'ALPHA ESTATE',
              el: 'ALPHA ESTATE'
            },
            type: {
              en: 'PINOT NOIR',
              el: 'PINOT NOIR'
            },
            producer: {
              en: 'Alpha Estate / wild fermentation',
              el: 'Alpha Estate / wild fermentation'
            }
          }
        ]
      }
    ]
  },
  {
    category: { en: 'DESSERT WINES', el: 'DESSERT WINES' },
    items: [
      {
        name: {
          en: 'SAMOS GRAND CRU WHITE',
          el: 'SAMOS GRAND CRU WHITE'
        },
        type: {
          en: 'MOSHÁTO SÁMOU',
          el: 'MOSHÁTO SÁMOU'
        },
        producer: {
          en: 'Samos Cooperation / PDO Sámos',
          el: 'Samos Cooperation / PDO Sámos'
        }
      },
      {
        name: {
          en: 'LIÁTIKO RED',
          el: 'LIÁTIKO RED'
        },
        type: {
          en: 'LIÁTIKO',
          el: 'LIÁTIKO'
        },
        producer: {
          en: 'Idéa Winery / PDO Dafnés',
          el: 'Idéa Winery / PDO Dafnés'
        }
      }
    ]
  },
  {
    category: { en: 'WINES BY THE GLASS', el: 'WINES BY THE GLASS' },
    items: [
      {
        name: {
          en: 'WHITE',
          el: 'WHITE'
        },
        type: {
          en: 'MALAGOUZIÁ\nSANTORÍNI MEGA CUVÉE',
          el: 'MALAGOUZIÁ\nSANTORÍNI MEGA CUVÉE'
        }
      },
      {
        name: {
          en: 'ROSE',
          el: 'ROSE'
        },
        type: {
          en: '4-6 H\nALPHA',
          el: '4-6 H\nALPHA'
        }
      },
      {
        name: {
          en: 'RED',
          el: 'RED'
        },
        type: {
          en: 'MAVROÚDI FINE\nALPHA ESTATE',
          el: 'MAVROÚDI FINE\nALPHA ESTATE'
        }
      },
      {
        name: {
          en: 'DESSERT',
          el: 'DESSERT'
        },
        type: {
          en: 'SAMOS GRAND CRU WHITE\nLIÁTIKO RED',
          el: 'SAMOS GRAND CRU WHITE\nLIÁTIKO RED'
        }
      }
    ]
  }
]

const extractItemsInfo = lang =>
  R.map(
    R.evolve({
      name: R.prop(lang),
      type: R.prop(lang),
      producer: R.prop(lang)
    })
  )

export const getWines = lang =>
  R.map(
    R.evolve({
      category: R.prop(lang),
      subCategories: R.map(
        R.evolve({
          name: R.prop(lang),
          items: extractItemsInfo(lang)
        })
      ),
      items: extractItemsInfo(lang)
    })
  )(wines)
