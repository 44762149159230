import styled from 'styled-components'
import Img from '../../components/Img'
import { createStatic } from 'styled-components-breakpoint'
import { primaryFont, secondaryFont } from '../../GlobalStyles'

export const TitleWrapper = styled.div`
  text-align: center;
  width: 300px;
  margin: 50px auto 30px;
`

export const PageTitle = styled.h1`
  ${primaryFont};
  color: #1a1818;
  font-size: 25px;
  margin: 0;
  font-weight: normal;
  white-space: pre-line;
`

export const Fishes = styled(Img).attrs({
  fadeIn: false,
  filename: '3-fish.png'
})`
  width: 50px;
  margin: 5px auto 0;
`

const breakpoint = createStatic({ bannerImg: 300, bannerImgBig: 400 })
export const BannerImgWrapper = styled.div`
  margin-bottom: 20px;
  height: 400px;
  position: relative;
  ${breakpoint.bannerImg`
    height: 500px;
  `};
  ${breakpoint.bannerImgBig`
    height: 600px;
  `};
`

export const FrontpageImg = styled(Img).attrs({
  filename: 'frontpage_banner.png',
  fadeIn: false,
  imgStyle: {
    objectFit: 'contain'
  }
})`
  width: 100vw;
  left: -30px;
  max-height: 100%;
  ${breakpoint.bannerImgBig`
    width: auto;
    left: auto;
  `};
`

export const ServingWrapper = styled.div`
  text-align: right;
  max-width: 440px;
  margin: 0 auto 60px;
`

export const ServingTitle = styled.span`
  ${secondaryFont};
  color: #a2a2a2;
  font-size: 16px;
`

export const H2 = styled.h2`
  margin: 0;
  ${primaryFont};
  font-size: 32px;
  font-weight: normal;
  color: #1a1818;
  white-space: pre-line;
`

export const InstaWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px;
`

export const Logo = styled(Img).attrs({
  filename: 'gallop_logo.png',
  fadeIn: false
})`
  width: 20px;
  margin-right: 20px;
`

export const InstaLink = styled.a`
  text-decoration: none;
  ${secondaryFont};
  font-size: 17px;
  color: #a2a2a2;
`
