import React from 'react'
import './css.css'
import { CarouselImg, ImageWrapper, NextArrow, PrevArrow, Slider } from './css'

const settings = {
  arrows: true,
  autoplay: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
}

const Carousel = ({ photos }) => {
  return (
    <Slider {...settings} nextArrow={<NextArrow />} prevArrow={<PrevArrow />}>
      {photos.map((pic, idx) => (
        <ImageWrapper key={idx}>
          <CarouselImg src={pic.src.low} />
        </ImageWrapper>
      ))}
    </Slider>
  )
}

export default Carousel
