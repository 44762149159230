import styled from 'styled-components'
import _Slider from 'react-slick'
import prevArrow from '../../images/arrows/arrow-left.png'
import nextArrow from '../../images/arrows/arrow-right.png'

export const Slider = styled(_Slider)`
  max-width: 600px;
  margin: 0 auto;
`

export const ImageWrapper = styled.div`
  height: 300px;
  position: relative;
`

export const CarouselImg = styled.img`
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const PrevArrow = styled.img.attrs({
  src: prevArrow
})``

export const NextArrow = styled.img.attrs({
  src: nextArrow
})``
