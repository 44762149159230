import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import logo from '../images/logo.png'

const Header = ({ siteTitle }) => (
  <Wrapper>
    <Link to="/">
      <Logo alt={siteTitle} src={logo} />
    </Link>
  </Wrapper>
)

const Wrapper = styled.header`
  margin-bottom: 1.45rem;
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Logo = styled.img`
  width: 75px;
  margin: 0;
`

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header
