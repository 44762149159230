import * as R from 'ramda'

const food = [
  {
    category: { en: 'salads', el: 'Σαλάτες' },
    icon: 'food/salads.png',
    items: [
      {
        title: { en: 'greek salad', el: 'Χωριάτικη' }
      },
      {
        title: { en: 'green', el: 'green' },
        description: {
          en:
            'with fresh mizithra cheese, nuts,\n& apples in greek ‘petimezi’ molasses',
          el:
            'with fresh mizithra cheese, nuts,\n& apples in greek ‘petimezi’ molasses'
        }
      },
      {
        title: {
          en: 'arugola & grilled eggplant',
          el: 'arugola & grilled eggplant'
        },
        description: {
          en: 'with pine nuts, tomato & garlic sauce',
          el: 'with pine nuts, tomato & garlic sauce'
        }
      },
      {
        title: { en: 'cycladic salad', el: 'cycladic salad' },
        description: {
          en:
            'cherry tomatoes, capers, xinotiri cheese\nrusk, caper leaves & sea fennel',
          el:
            'cherry tomatoes, capers, xinotiri cheese\nrusk, caper leaves & sea fennel'
        }
      },
      {
        title: { en: 'black-eyed peas salad', el: 'black-eyed peas salad' },
        description: {
          en:
            'with avocado, cucumber & spring onion\noptional with marinated small fish',
          el:
            'with avocado, cucumber & spring onion\noptional with marinated small fish'
        }
      },
      {
        title: { en: 'salad with fresh salmon', el: 'salad with fresh salmon' },
        description: {
          en: 'spinach & marinated zucchini,\norange & yogurt sauce',
          el: 'spinach & marinated zucchini,\norange & yogurt sauce'
        }
      }
    ]
  },
  {
    category: { en: 'mykonian & cretan', el: 'mykonian & cretan' },
    icon: 'food/traditional.png',
    items: [
      {
        title: {
          en: 'popping fried snails from Crete',
          el: 'popping fried snails from Crete'
        },
        description: { en: 'with rosemary', el: 'with rosemary' }
      },
      {
        title: { en: 'cheese pastries', el: 'cheese pastries' }
      },
      {
        title: {
          en: 'watermelon with feta\n& xinotiri cheese',
          el: 'watermelon with feta\n& xinotiri cheese'
        },
        description: {
          en: '‘rakomelo’ & fresh mint',
          el: '‘rakomelo’ & fresh mint'
        }
      },
      {
        title: { en: 'local cheese selection', el: 'local cheese selection' },
        description: {
          en:
            'cretan & mykonian cheeses\nwith honey, tomato marmalade & sweet olive',
          el:
            'cretan & mykonian cheeses\nwith honey, tomato marmalade & sweet olive'
        }
      },
      {
        title: { en: 'Greek Spread Platter', el: 'Greek Spread Platter' },
        description: {
          en:
            'tzatziki, tarama, spicy cheese spread, \nfava, eggplant spread\nwith pita bread & garlic pepper',
          el:
            'tzatziki, tarama, spicy cheese spread, \nfava, eggplant spread\nwith pita bread & garlic pepper'
        }
      }
    ]
  },
  {
    category: { en: 'starters', el: 'starters' },
    icon: 'food/starters.png',
    items: [
      {
        title: { en: 'tzatziki', el: 'tzatziki' }
      },
      {
        title: { en: 'houmous with pita', el: 'houmous with pita' }
      },
      {
        title: { en: 'tarama', el: 'tarama' }
      },
      {
        title: { en: 'dolmadakia', el: 'dolmadakia' },
        description: { en: 'stuffed vine leaves', el: 'stuffed vine leaves' }
      },
      {
        title: { en: 'fried zucchini', el: 'fried zucchini' },
        description: {
          en: 'cheese-stuffed pepper on the grill',
          el: 'cheese-stuffed pepper on the grill'
        }
      },
      {
        title: {
          en: 'grilled halloumi cheese ',
          el: 'grilled halloumi cheese '
        },
        description: {
          en: 'with tomato marmalade',
          el: 'with tomato marmalade'
        }
      }
    ]
  },
  {
    category: { en: 'seafood starters', el: 'seafood starters' },
    icon: 'food/seafood_starters.png',
    items: [
      {
        title: { en: '‘kakavia’', el: '‘kakavia’' },
        description: { en: 'greek fish soup', el: 'greek fish soup' }
      },
      {
        title: { en: 'marinated small fish', el: 'marinated small fish' }
      },
      {
        title: { en: 'fried small fish', el: 'fried small fish' }
      },
      {
        title: { en: 'sardines on the grill', el: 'sardines on the grill' }
      },
      {
        title: {
          en: 'grilled octopus with fava',
          el: 'grilled octopus with fava'
        }
      },
      {
        title: { en: 'fried calamari', el: 'fried calamari' }
      },
      {
        title: { en: 'mixed fried seafood', el: 'mixed fried seafood' }
      },
      {
        title: { en: 'seabass ceviche', el: 'seabass ceviche' },
        description: {
          en: 'with local greens, lime & orange',
          el: 'with local greens, lime & orange'
        }
      },
      {
        title: { en: 'shrimps saganaki', el: 'shrimps saganaki' },
        description: {
          en: 'with ouzo & feta cheese',
          el: 'with ouzo & feta cheese'
        }
      },
      {
        title: { en: 'shrimps in garlic sauce', el: 'shrimps in garlic sauce' }
      }
    ]
  },
  {
    category: { en: 'seafood', el: 'seafood' },
    icon: 'food/seafood.png',
    items: [
      {
        title: {
          en: 'mussels with tomato & basil',
          el: 'mussels with tomato & basil'
        }
      },
      {
        title: {
          en: 'mussels with lemon & feta cheese',
          el: 'mussels with lemon & feta cheese'
        }
      },
      {
        title: { en: 'calamari on the grill', el: 'calamari on the grill' }
      },
      {
        title: { en: 'swordfish on the grill', el: 'swordfish on the grill' }
      },
      {
        title: { en: 'shrimps on the grill', el: 'shrimps on the grill' }
      },
      {
        title: { en: 'salmon on the grill', el: 'salmon on the grill' }
      },
      {
        title: { en: 'mousaka with shrimps', el: 'mousaka with shrimps' }
      },
      {
        title: {
          en: 'fried fish fillet & zucchini chips',
          el: 'fried fish fillet & zucchini chips'
        },
        description: { en: 'with tartar sauce', el: 'with tartar sauce' }
      },
      {
        title: { en: 'sea bass in salt crust', el: 'sea bass in salt crust' }
      },
      {
        title: { en: 'local lobster', el: 'local lobster' },
        description: {
          en: 'served with pasta or on the grill',
          el: 'served with pasta or on the grill'
        }
      },
      {
        title: { en: 'fish of the day', el: 'fish of the day' }
      }
    ]
  },
  {
    category: { en: 'pasta/risotto', el: 'pasta/risotto' },
    icon: 'food/pasta.png',
    items: [
      {
        title: {
          en: 'linguine with fresh salmon',
          el: 'linguine with fresh salmon'
        },
        description: { en: '& spinach', el: '& spinach' }
      },
      {
        title: { en: 'linguine with calamari,', el: 'linguine with calamari,' },
        description: {
          en: 'zucchini & fresh spearmint',
          el: 'zucchini & fresh spearmint'
        }
      },
      {
        title: { en: 'spaghetti with shrimps', el: 'spaghetti with shrimps' },
        description: {
          en: 'spaghetti with seafood',
          el: 'spaghetti with seafood'
        }
      },
      {
        title: { en: 'risotto with seafood', el: 'risotto with seafood' }
      },
      {
        title: { en: 'spaghetti with vongole', el: 'spaghetti with vongole' }
      },
      {
        title: {
          en: 'spaghetti with seafood for 2',
          el: 'spaghetti with seafood for 2'
        }
      },
      {
        title: { en: 'risotto with shrimps', el: 'risotto with shrimps' },
        description: { en: '& zucchini flowers', el: '& zucchini flowers' }
      },
      {
        title: {
          en: 'traditional risoni with shrimps',
          el: 'traditional risoni with shrimps'
        }
      },
      {
        title: {
          en: 'traditional risoni with lobster',
          el: 'traditional risoni with lobster'
        }
      },
      {
        title: {
          en: 'spaghetti with garlic, olive oil ',
          el: 'spaghetti with garlic, olive oil '
        },
        description: { en: '& peperoncino', el: '& peperoncino' }
      },
      {
        title: { en: 'mykonian penne', el: 'mykonian penne' },
        description: {
          en: 'with xinotiri cheese, sun-dried tomato \n& arugola',
          el: 'with xinotiri cheese, sun-dried tomato \n& arugola'
        }
      },
      {
        title: { en: 'risotto with vegetables', el: 'risotto with vegetables' }
      },
      {
        title: { en: 'spaghetti bolognese', el: 'spaghetti bolognese' }
      },
      {
        title: { en: 'penne with chicken,', el: 'penne with chicken,' },
        description: { en: 'eggplant & basil', el: 'eggplant & basil' }
      },
      {
        title: {
          en: 'risotto with porcini mushrooms',
          el: 'risotto with porcini mushrooms'
        },
        description: { en: '& truffle oil', el: '& truffle oil' }
      }
    ]
  },
  {
    category: { en: 'meat', el: 'meat' },
    icon: 'food/meat.png',
    items: [
      {
        title: {
          en: 'chicken chops on the grill',
          el: 'chicken chops on the grill'
        }
      },
      {
        title: {
          en: 'chicken fillet in lemon sauce',
          el: 'chicken fillet in lemon sauce'
        }
      },
      {
        title: {
          en: 'beef patty burgers\nwith roquefort sauce',
          el: 'beef patty burgers\nwith roquefort sauce'
        }
      },
      {
        title: { en: 'mykonian lamb chops', el: 'mykonian lamb chops' }
      },
      {
        title: {
          en: 'lamb shank with honey & mustard',
          el: 'lamb shank with honey & mustard'
        },
        description: {
          en: 'with caramelised oven potatoes',
          el: 'with caramelised oven potatoes'
        }
      },
      {
        title: { en: 'rib-eye', el: 'rib-eye' },
        description: {
          en: 'potatoes with feta & basil sauce & truffle oil',
          el: 'potatoes with feta & basil sauce & truffle oil'
        }
      },
      {
        title: { en: 'meat variety for 2', el: 'meat variety for 2' },
        description: {
          en: 'chicken chops, lamb chops,\nbeef patty burger, sausage',
          el: 'chicken chops, lamb chops,\nbeef patty burger, sausage'
        }
      }
    ]
  },
  {
    category: { en: 'side dishes', el: 'side dishes' },
    icon: 'food/side_dishes.png',
    items: [
      {
        title: { en: 'rice', el: 'rice' }
      },
      {
        title: { en: 'homemade fried potatoes', el: 'homemade fried potatoes' }
      },
      {
        title: {
          en: 'local greens sautéed with garlic',
          el: 'local greens sautéed with garlic'
        }
      },
      {
        title: {
          en: 'fried potatoes with feta',
          el: 'fried potatoes with feta'
        },
        description: {
          en: 'cheese sauce & truffle oil',
          el: 'cheese sauce & truffle oil'
        }
      },
      {
        title: { en: 'oven-baked potatoes', el: 'oven-baked potatoes' },
        description: { en: 'with lemon & thyme', el: 'with lemon & thyme' }
      },
      {
        title: {
          en: 'broccoli sautéed with garlic',
          el: 'broccoli sautéed with garlic'
        }
      },
      {
        title: { en: 'imam eggplant', el: 'imam eggplant' },
        description: { en: 'with tomato & feta', el: 'with tomato & feta' }
      },
      {
        title: { en: 'vegetables on the grill', el: 'vegetables on the grill' }
      },
      {
        title: { en: 'chopped salad', el: 'chopped salad' },
        description: {
          en: 'with lemon & chilly peppers',
          el: 'with lemon & chilly peppers'
        }
      }
    ]
  }
]

export const getFood = lang =>
  R.map(
    R.evolve({
      category: R.prop(lang),
      items: R.map(
        R.evolve({
          description: R.prop(lang),
          title: R.prop(lang)
        })
      )
    })
  )(food)
