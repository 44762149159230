import React from 'react'
import PropTypes from 'prop-types'

const Tripadvisor = ({ width = 35 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={width * 0.625}
    viewBox="0 0 8.432 5.012">
    <g fill="#58595B" fillRule="evenodd" clipRule="evenodd">
      <path d="M4.658 4.263l-.451.603-.447-.598c-.468.445-.977.735-1.63.74C.662 5.022-.412 3.554.154 2.197c.205-.494.22-.874-.096-1.264C.093.888.113.847.125.847c.949.158 1.731-.39 2.592-.604 1.39-.349 2.74-.262 4.034.408.184.099.414.127.626.142.313.024.63.008 1.001.008-.055.137-.077.218-.118.286-.198.315-.233.599-.039.969.438.83.164 1.907-.561 2.486a2.164 2.164 0 0 1-2.669.019c-.103-.084-.196-.176-.333-.298zm3.328-1.329c.004-.942-.751-1.709-1.675-1.702a1.687 1.687 0 0 0-1.662 1.674c-.005.942.754 1.707 1.68 1.695a1.662 1.662 0 0 0 1.657-1.667zm-4.206.004c.006-.943-.748-1.716-1.675-1.711A1.683 1.683 0 0 0 .444 2.906a1.65 1.65 0 0 0 1.653 1.676A1.651 1.651 0 0 0 3.78 2.938zm.434-.397c.306-1.009.907-1.535 1.817-1.737C5.388.305 3.015.302 2.388.794c.909.207 1.521.727 1.826 1.747z" />
      <path d="M6.311 1.854c.544 0 1.038.483 1.048 1.024.009.556-.495 1.055-1.064 1.058-.522 0-1.029-.517-1.026-1.051.003-.537.503-1.031 1.042-1.031zm.68 1.02a.701.701 0 0 0-.685-.654c-.367.007-.696.357-.67.714.026.34.376.656.708.637.339-.017.659-.361.647-.697zM1.055 2.886c.002-.553.479-1.034 1.026-1.034.552 0 1.023.472 1.032 1.029.009.57-.471 1.053-1.052 1.056-.533 0-1.009-.499-1.006-1.051zm1.02-.669c-.346.003-.659.322-.66.674a.697.697 0 0 0 .653.682c.367.012.681-.314.674-.697a.664.664 0 0 0-.667-.659z" />
      <path d="M6.32 3.379c-.163-.222-.348-.368-.325-.465.031-.142.218-.244.339-.363.108.116.291.227.304.354.009.116-.16.252-.318.474zM2.566 2.909c-.218.148-.354.314-.474.304-.119-.013-.332-.224-.313-.299.035-.142.196-.317.331-.342.101-.021.244.171.456.337z" />
    </g>
  </svg>
)

Tripadvisor.propTypes = {
  width: PropTypes.number
}

export default Tripadvisor
