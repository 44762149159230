import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'

const Image = props => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                sizes(maxWidth: 600) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { filename, alt, ...restProps } = props
      const image = data.images.edges.find(n =>
        n.node.relativePath.includes(filename)
      )
      if (!image) {
        return null
      }

      const imageSizes = image.node.childImageSharp.sizes
      return <Img alt={alt} {...restProps} sizes={imageSizes} />
    }}
  />
)

Image.propTypes = {
  filename: PropTypes.string.isRequired
}

export default Image
