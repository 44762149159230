import { getFood } from './food'
import { getWines } from './wines'

export default {
  book_form_note: 'Please make sure you receive the confirmation e-mail.',
  book_input_email: 'email',
  book_input_date: 'date',
  book_input_name: 'name',
  book_input_notes: 'notes (optional)',
  book_input_persons: 'persons',
  book_input_phone: 'phone number',
  book_input_time: 'time',
  book_submit_button: 'BOOK A TABLE',
  book_success_message:
    'Your message has been sent. \nPlease check your email \n for availability & confirmation',
  book_success_button: 'ok',
  book_title: 'Reservation Form',
  contact_foot_note:
    "Any questions, requests or just want to say hello?\n We're always happy to hear from you.\nYou can also find us on Facebook, Instagram, TripAdvisor or you can just drop by to meet.",
  contact_input_message: 'your message',
  contact_submit_button: 'GET IN TOUCH',
  contact_success_message: `Your message has been sent.\nThank you for contacting us!\n:)`,
  contact_title: 'Contact Form',
  error_email: 'Please check your e-mail address and try again.',
  error_forgot: 'Forgot something?\n',
  error_required_fields: 'Please fill all fields and try again.',
  error_title: `Ooops!\nThere might be something wrong.\n`,
  home_heading: 'Page under construction',
  home_serving: 'serving\nnew delicacies\nevery day\nwith a smile',
  home_serving_title: "TODAY'S SPECIALS",
  home_title: 'CELEBRATING\nLOCAL FLAVOURS\nSINCE 1984',
  lang_en: 'English',
  lang_el: 'Ελληνικα',
  location_find_us_info:
    'You can find us after passing by the turn to Psarrou Beach just 500m before you reach Platys Gialos Beach.',
  location_how_to_get_here: 'HOW TO GET HERE:',
  location_MMM_desc: 'Destination to Platys Gialos Beach',
  location_MMM_type: 'Local Bus/ Boat:',
  location_vehicle_desc:
    'After the crossroad to Psarrou, Οpposite Grecotel Mykonos Blu Hotel',
  location_vehicle_type: 'Vehicle:',
  meet_dining_header: 'DINING\nIN GREECE',
  meet_dining_bottom:
    'Because at the end of the day, food and wine are our way of welcoming old and new friends into our homes, sharing a good story or having a laugh.',
  meet_dining_top:
    'Great food and carefully selected producers are not enough to truly enjoy a meal. Because good food is meant to be shared.\nThis is why a lot  of Greek dishes are meze, spreads or platters that are meant to be shared. It is the same philosophy of sharing that guides people across the Mediterranean to share products, recipes & culinary customs.',
  meet_lexicon: 'LEXICON',
  meet_local_producers: 'LOCAL\nPRODUCERS',
  meet_producers_text:
    'We choose local producers and ingredients to support our local community and honestly because we love it.\nFrom Mykonian fishermen, to Cretan olive oil producers and famers from Tinos, our menu is carefully crafted to offer visitors a variety of real Mediterranean flavors.',
  meet_provide_text:
    'Today, we continue to provide our\n visitors with an experience that combines\n traditional and modern flavors.',
  meet_sophia_text: 'Meet Sophia.\nResident cat &\n seafood aficionado',
  meet_story_text: `We welcomed our first visitors back in 1984.\nA place for locals and visitors alike, Nikos Gallop Restaurant was born out of our love for great food, good wine, good company and good conversation.`,
  meet_the_story: `THE\nSTORY`,
  menu_book: 'BOOK',
  menu_contact: 'CONTACT',
  menu_food: 'FOOD',
  menu_location: 'LOCATION',
  menu_meet_us: 'MEET US',
  menu_wine: 'WINE',
  footer_company_name: 'NIKOS GALLOP RESTAURANT',
  footer_company_address_1: 'Platys Gialos',
  footer_company_address_2: 'Mykonos, Greece',
  view_map: 'view map',
  lexicon: [
    {
      title: 'A-NTHO-TI-RO:',
      description: 'mykonian cheese with a salty aftertaste'
    },
    {
      title: 'DOL-MA-DA-KIA:',
      description: 'wine leaves stuffed with rice, vegetables & herbs'
    },
    {
      title: 'GRA-VIÉ-RA:',
      description: 'cretan cheese, aged'
    },
    {
      title: 'HA-LOÚ-MI:',
      description: 'cypriot cheese, typically served grilled'
    },
    {
      title: 'KA-KA-VIÁ:',
      description: 'aegean fish soup with a variety of fish'
    },
    {
      title: 'KO-PA-NI-STÍ:',
      description: 'mykonian cheese, spicy & creamy'
    },
    {
      title: 'MI-ZÍ-THRA:',
      description: 'cretan cheese, fresh white'
    },
    {
      title: 'MOU-SA-KÁ:',
      description:
        'a greek cuisine staple, with layers of eggplant, potato, beef minced meat & béchamel sauce'
    },
    {
      title: 'PE-TI-MÉ-ZI: ',
      description: 'greek molasses made of grapes'
    },
    {
      title: 'RA-KÓ-ME-LO:',
      description: ''
    },
    {
      title: 'TA-RA-MA:',
      description: 'spread made with white codfish eggs'
    },
    {
      title: 'TI-RO-VO-LIA:',
      description: 'mykonian cheese, white & refreshing'
    },
    {
      title: 'TZA-TZI-KI:',
      description: 'classic greek spread,\n made of yogurt, cucumber and garlic'
    },
    {
      title: 'XI-NO-TÍ-RI: ',
      description: 'mykonian cheese with a salty aftertaste'
    }
  ],
  food: getFood('en'),
  wines: getWines('en')
}
