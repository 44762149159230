import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import { I18nProvider } from '../i18n'

import Header from './header'
import Menu from './Menu'
import Footer from './Footer'
import ScrollToTop from 'react-scroll-up'
import './layout.css'
import pageBackground from '../images/page_background.png'
import upArrow from '../images/arrows/arrow-up.png'

const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <I18nProvider>
        <Wrapper>
          <Header siteTitle={data.site.siteMetadata.title} />
          <Menu location={location} />
          <MainSection>
            <main>{children}</main>
          </MainSection>
          <Footer />
        </Wrapper>
        <ModalPlaceholder id="modal-root" />
        <ScrollToTop showUnder={160}>
          <UpArrow />
        </ScrollToTop>
      </I18nProvider>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout

const Wrapper = styled.div`
  position: relative;
  background-image: url(${pageBackground});
  background-size: 100px 100px;
  min-height: 100%;
  > * {
    max-width: 960px;
    margin: 0 auto;
  }
`

const MainSection = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 0 30px 1.45rem;
`

const ModalPlaceholder = styled.div`
  display: none;
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  > div {
    width: 100%;
    height: 100%;
  }
`

const UpArrow = styled.img.attrs({
  src: upArrow
})`
  width: 40px;
`
