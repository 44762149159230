import React from 'react'
import Layout from '../layout'
import Home from '../screens/Home'

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <Home />
  </Layout>
)

export default IndexPage
