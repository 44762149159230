import * as R from 'ramda'

export const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

const getResolutions = image => ({
  low: R.path(['images', 'low_resolution', 'url'], image),
  high: R.path(['images', 'standard_resolution', 'url'], image)
})

const extractPhotoInfo = R.compose(
  R.reduce((acc, p) => {
    const caption = R.path(['caption', 'text'], p)
    if (p.type === 'carousel') {
      R.compose(
        R.forEach(carouselPhoto => {
          const photo = {
            src: getResolutions(carouselPhoto),
            caption
          }
          acc = R.append(photo, acc)
        }),
        R.propOr([], 'carousel_media')
      )(p)
    } else {
      const photo = {
        src: getResolutions(p),
        caption
      }
      acc = R.append(photo, acc)
    }
    return acc
  }, []),
  R.filter(p => p.type !== 'video'),
  R.propOr([], 'data')
)

const ACCESS_TOKEN = '3190213659.2f2384c.455fc9edfd5a4f83805d65f1e074a009'
const CLIENT_ID = '3190213659'
export const getInstagramPhotos = async (count = 25) => {
  const response = await fetch(
    `https://api.instagram.com/v1/users/${CLIENT_ID}/media/recent/?access_token=${ACCESS_TOKEN}&count=${count}`
  )
  if (!response.ok) return []
  const data = await response.json()

  const photos = extractPhotoInfo(data)
  return R.take(count, photos)
}
