import styled from 'styled-components'
import boatImg from '../../images/footer-boat.png'
import { primaryFont } from '../../GlobalStyles'

export const Wrapper = styled.footer`
  background: rgba(255, 255, 255, 0.7);
  max-width: 100% !important;
`

export const Content = styled.div`
  padding: 25px 0;
  margin: 0 auto;
  max-width: 960px;
`

export const SocialArea = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SocialLink = styled.a.attrs({
  target: '_blank'
})`
  margin-right: 40px;
  :last-child {
    margin-right: 0;
  }
`
export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  color: #000;
  max-width: 800px;
  width: 100%;
  background-image: url(${boatImg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 10px center;
  > span {
    ${primaryFont};
    font-size: 16px;
    line-height: 1.2;
  }
`

export const Title = styled.h1`
  ${primaryFont};
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
`

export const GoogleMapsLink = styled.a.attrs({
  target: '_blank'
})`
  ${primaryFont};
  text-decoration: none;
  color: #03838f;
  margin: 15px 0;
`

export const LangWrapper = styled.div`
  ${primaryFont};
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding: 2px 0;
  display: flex;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
  img {
    height: 20px;
    margin-right: 10px;
  }
`
