import { useTranslation } from '../../i18n'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import React, { useEffect } from 'react'
import { getInstagramPhotos } from '../../utils'
import SEO from '../../components/seo'
import Carousel from '../../components/Carousel'
import {
  BannerImgWrapper,
  Fishes,
  FrontpageImg,
  H2,
  InstaLink,
  InstaWrapper,
  Logo,
  PageTitle,
  ServingTitle,
  ServingWrapper,
  TitleWrapper
} from './css'

const Home = () => {
  const { t } = useTranslation()
  const [photos, setPhotos] = useLocalStorage('photos', [])

  useEffect(() => {
    async function getPhotos() {
      const instagramPhotos = await getInstagramPhotos()
      setPhotos(instagramPhotos)
    }
    getPhotos()
  }, [])
  return (
    <>
      <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
      <TitleWrapper>
        <PageTitle>{t('home_title')}</PageTitle>
        <Fishes />
      </TitleWrapper>
      <BannerImgWrapper>
        <FrontpageImg />
      </BannerImgWrapper>
      <ServingWrapper>
        <ServingTitle>{t('home_serving_title')}</ServingTitle>
        <H2>{t('home_serving')}</H2>
      </ServingWrapper>
      <InstaWrapper>
        <Logo />
        <InstaLink
          href="https://www.instagram.com/explore/tags/nikosgallop/"
          target="_blank">
          #nikosgallop
        </InstaLink>
      </InstaWrapper>
      <Carousel photos={photos} />
    </>
  )
}

export default Home
