import React from 'react'
import enTranslations from './locales/en'
import elTranslations from './locales/el'
import * as R from 'ramda'

export const TranslationContext = React.createContext({})

const TRANSLATIONS = {
  en: enTranslations,
  el: elTranslations
}

const DEFAULT_LANG = 'en'

const getDefaultLocale = () => {
  const locale = localStorage.getItem('locale')
  if (TRANSLATIONS[locale]) return locale

  const browserLang =
    (navigator.languages && navigator.languages[0]) || navigator.language
  if (TRANSLATIONS[browserLang]) return browserLang

  return DEFAULT_LANG
}

class TranslationProvider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      locale: DEFAULT_LANG,
      messages: TRANSLATIONS[DEFAULT_LANG],
      t: this.translateKey,
      changeLocale: this.changeLocale,
      availableTranslations: R.keys(TRANSLATIONS)
    }
  }

  componentDidMount() {
    const initialLang = getDefaultLocale()
    this.setState({
      locale: initialLang,
      messages: TRANSLATIONS[initialLang]
    })
  }

  translateKey = key => {
    const { messages } = this.state
    return messages[key] ? messages[key] : key
  }

  changeLocale = newLocale => {
    if (TRANSLATIONS[newLocale]) {
      this.setState({
        locale: newLocale,
        messages: TRANSLATIONS[newLocale]
      })
      localStorage.setItem('locale', newLocale)
    }
  }

  render() {
    const { children } = this.props
    return (
      <TranslationContext.Provider value={this.state}>
        {children}
      </TranslationContext.Provider>
    )
  }
}

export default TranslationProvider
