import React from 'react'
import { useTranslation } from '../../i18n'
import { MenuIcon, LinkItem, Row, Wrapper, Text, WaveBackground } from './css'
import foodIcon from '../../images/menu/food.png'
import wineIcon from '../../images/menu/wine.png'
import meetUsIcon from '../../images/menu/meet-us.png'
import locationIcon from '../../images/menu/location.png'
import bookIcon from '../../images/menu/book.png'
import contactIcon from '../../images/menu/contact.png'

const Menu = ({ location }) => {
  const { t } = useTranslation()
  const { pathname } = location
  const isHomePage = pathname === '/'
  return (
    <Wrapper>
      <Row>
        <LinkItem to="/food/" proper={isHomePage ? 1 : 0}>
          <MenuIcon src={foodIcon} />
          <Text>{t('menu_food')}</Text>
        </LinkItem>
        <LinkItem to="/wine/" proper={isHomePage ? 1 : 0}>
          <MenuIcon src={wineIcon} />
          <Text>{t('menu_wine')}</Text>
        </LinkItem>
        <LinkItem to="/meet-us/" proper={isHomePage ? 1 : 0}>
          <MenuIcon src={meetUsIcon} />
          <Text>{t('menu_meet_us')}</Text>
        </LinkItem>
        <WaveBackground />
      </Row>
      <Row>
        <LinkItem to="/location/" proper={isHomePage ? 1 : 0}>
          <MenuIcon src={locationIcon} />
          <Text>{t('menu_location')}</Text>
        </LinkItem>
        <LinkItem to="/book/" proper={isHomePage ? 1 : 0}>
          <MenuIcon src={bookIcon} />
          <Text>{t('menu_book')}</Text>
        </LinkItem>
        <LinkItem to="/contact/" proper={isHomePage ? 1 : 0}>
          <MenuIcon src={contactIcon} />
          <Text>{t('menu_contact')}</Text>
        </LinkItem>
        <WaveBackground />
      </Row>
    </Wrapper>
  )
}

export default Menu
