import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from '../../i18n'
import * as R from 'ramda'
import elIcon from '../../images/flags/greek.png'
import enIcon from '../../images/flags/english.png'
import TripadvisorIcon from '../../components/images/Tripadvisor'
import FacebookIcon from '../../components/images/Facebook'
import InstagramIcon from '../../components/images/Instagram'
import {
  Content,
  GoogleMapsLink,
  Info,
  LangWrapper,
  SocialArea,
  SocialLink,
  Title,
  Wrapper
} from './css'

const tripadvisorUrl =
  'https://www.tripadvisor.com.gr/Restaurant_Review-g659660-d3443554-Reviews-Nikos_Gallop_Restaurant-Platys_Gialos_Mykonos_Cyclades_South_Aegean.html'
const facebookUrl = 'https://www.facebook.com/nikos.gallop/'
const instagramUrl = 'https://www.instagram.com/nikos.gallop'
const googleMapsUrl = 'https://goo.gl/maps/Rb7ynRz8NJ52'

const langIcons = {
  en: enIcon,
  el: elIcon
}

const Footer = ({ className }) => {
  const { t, availableTranslations, locale, changeLocale } = useTranslation()
  const otherTranslations = R.reject(R.equals(locale), availableTranslations)
  return (
    <Wrapper className={className}>
      <Content>
        <SocialArea>
          <SocialLink href={tripadvisorUrl}>
            <TripadvisorIcon />
          </SocialLink>
          <SocialLink href={facebookUrl}>
            <FacebookIcon />
          </SocialLink>
          <SocialLink href={instagramUrl}>
            <InstagramIcon />
          </SocialLink>
        </SocialArea>
        <Info>
          <Title>{t('footer_company_name')}</Title>
          <span>{t('footer_company_address_1')}</span>
          <span>{t('footer_company_address_2')}</span>
          <span>+30 22890 24306</span>
          <span>info@nikosgallop.com</span>
          <GoogleMapsLink href={googleMapsUrl}>{t('view_map')}</GoogleMapsLink>
          {otherTranslations.map(lang => (
            <LangWrapper key={lang} onClick={() => changeLocale(lang)}>
              <img src={langIcons[lang]} alt={lang} />
              {t(`lang_${lang}`)}
            </LangWrapper>
          ))}
        </Info>
      </Content>
    </Wrapper>
  )
}

Footer.propTypes = {
  className: PropTypes.string
}

export default Footer
